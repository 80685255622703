<template>
  <div>
    <hero-alt
      img="https://image.freepik.com/photos-gratuite/ouvrir-voler-vieux-livres_1232-2096.jpg"
      title="Liste des notices thermostat"
    />
    <table-view />
    <upload/>
  </div>
</template>

<script>
export default {
  name: "Notice",

  metaInfo: { title: "Notice" },

  data: () => ({}),

  components: {
    HeroAlt: () => import("@/components/mixins/HeroAlt"),
    TableView: () => import("@/components/AdminFileList"),
    upload: () => import("@/components/UploadNotice"),
  }
};
</script>
